<template>
  <div class="ui-slider-item">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ui-slider-item',

  props: {
    value: {
      required: false,
      default: null,
    },
  },
};
</script>